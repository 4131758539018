import styled from "styled-components";


// HEADER
export const Slider = styled.div`
color: white;
/* border: solid 1px white; */
border-radius: 4px;
width: 100%;
height: 50vh;
text-align: center;
display: grid;
grid-template-columns: 1fr 1fr 1fr;
justify-content: center;
align-items: center;
text-align: center;
`


// Left Right Container
export const LeftContainer = styled.div``
export const RightContainer = styled.div``



// Container
export const CenterContainer = styled.div`
text-align: left;
`


// Texts
export const PsmallSlider = styled.p`
color: white;
font-size: 18px;
line-height: 1;
margin-bottom: 5px;

/* responsive xs */
@media screen and (min-width: 1px) and (max-width: 767px) {
    font-size: 16px;

 }
 /* responsive sm  */
 @media screen and (min-width: 768px) and (max-width: 991px) {
    font-size: 16px; 
 }
 /* responsive md  */
 @media screen and (min-width: 992px) and (max-width: 1199px) {
    font-size: 18px;
 }
`
export const H1Slider = styled.h1`
color: white;
font-size: 72px;
font-weight: bold;
line-height: 1;
margin-bottom: 15px;


/* responsive xs */
@media screen and (min-width: 1px) and (max-width: 767px) {
    font-size: 32px;

 }
 /* responsive sm  */
 @media screen and (min-width: 768px) and (max-width: 991px) {
    font-size: 32px; 

 }
 /* responsive md  */
 @media screen and (min-width: 992px) and (max-width: 1199px) {
    font-size: 42px;
 }
`


export const PlargeSlider = styled.p`
color: white;
font-size: 24px;
line-height: 1;

/* responsive xs */
@media screen and (min-width: 1px) and (max-width: 767px) {
    font-size: 14px;

 }
 /* responsive sm  */
 @media screen and (min-width: 768px) and (max-width: 991px) {
    font-size: 18px; 
 }
 /* responsive md  */
 @media screen and (min-width: 992px) and (max-width: 1199px) {
    font-size: 20px;
 }



`



// Left Right Arrows
export const LeftArrowSlider = styled.img`
width: 50px;
cursor: pointer;

/* responsive xs */
@media screen and (min-width: 1px) and (max-width: 767px) {
    width: 30px;

 }
 /* responsive sm  */
 @media screen and (min-width: 768px) and (max-width: 991px) {
    font-size: 40px; 

 }
`
export const RightArrowSlider = styled.img`
width: 50px;
cursor: pointer;

/* responsive xs */
@media screen and (min-width: 1px) and (max-width: 767px) {
    width: 30px;

 }
 /* responsive sm  */
 @media screen and (min-width: 768px) and (max-width: 991px) {
    font-size: 40px; 

 }
`
