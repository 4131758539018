import styled from "styled-components";



// HEADER
export const HeaderFlex = styled.header`
background-color: black;
color: white;
width: 100%;
`






// BODY
export const BodyFlex = styled.body`
background-color: #506D84;
`
// CONSTAINER
export const ContainerFlex = styled.div`
/* background-color: #6B4F4F; */
width: 90%;
margin: auto;
padding: 40px 0;
gap: 20px;
display: flex;
`





// CONTAINER TARJETAS
export const CardsContainerFlex = styled.div`
background-color: #E8F6EF;
padding: 10px;
width: 70%;
display: flex;
gap: 10px;
/* flex-wrap: wrap; */
`


// ELEMENTOS DENTRO DEL BODY
export const Caja1 = styled.div`
background-color: #161853;
border-radius: 10px;
min-height: 200px;
padding: 20px;
position: relative;
overflow: hidden;
background-size: cover;
background-position: center center;
color: white;
`

export const Caja2 = styled.div`
background-color: #F05454;
border-radius: 10px;
min-height: 200px;
padding: 20px;
position: relative;
overflow: hidden;
background-size: cover;
background-position: center center;
color: white;
`

export const Caja3 = styled.div`
background-color: #9145B6;
border-radius: 10px;
min-height: 200px;
padding: 20px;
position: relative;
overflow: hidden;
background-size: cover;
background-position: center center;
color: white;
`


// SIDEBAR
export const SidebarFlex = styled.div`
width: 30%;
background-color: #3FA796;
color: white;
`








// FOOTER
export const FooterFlex = styled.footer`
background-color: black;
color: white;
width: 100%;
`