import styled from "styled-components";
import { screenSizes } from "../../utilities/ScreenSizes";

const { mobile, tablet, pc } = screenSizes;










// HEADER
export const HeaderGrid = styled.header`
background-color: #1A374D;
display: grid;
text-align: center;
justify-content: center;
align-items: center;
grid-template-columns: 1fr;



`
// SPAN DESCRIPCION 
export const SpanDescripcionHeaderGrid = styled.span`
color: orange;
margin-left: 5px;


`
// CONTAINER DE LA IMAGEN

export const ImgContainerGrid = styled.span`

`


export const ImgDominicanLogoGrid = styled.img`
width: 100px;
`







// CONTAINER
export const ContainerGrid = styled.div`


width: 100%;
text-align: center;
margin: auto;
line-height: 60px;
display: grid;
grid-template-columns: 1fr;
grid-template-rows:  repeat(1, 1fr);


`



export const DivCentralGrid = styled.div`
/* background-color: transparent; */
background-color: darkgray;
box-shadow: 2px 2px 10px rgba(0,0,0, .25);
display: block;
color: white;
display: grid;
height: 100vh;
width: 100%;
grid-template-columns: repeat(1, 1fr);
/* grid-template-rows: 1fr;
grid-row-start: 2;
grid-row-end: -1; */
`



export const SpanDescripGrid = styled.span`
color:  ${props => props.color};
font-size:  ${props => props.size}px;
font-family: Helvetica Neue;


/* responsive xs */
@media screen and (min-width: 1px) and (max-width: 767px) {
display: none;
}
/* responsive sm  */
@media screen and (min-width: 768px) and (max-width: 991px) {
    display: none;
}

/* responsive md  */
@media screen and (min-width: 992px) and (max-width: 1199px) {
    font-size: 8px;  
}
`
export const A_Grid = styled.a`
color:  ${props => props.color};
font-size:  ${props => props.size}px;


/* responsive md  */
@media screen and (min-width: 992px) and (max-width: 1199px) {
    font-size: 8px;  
}
`




export const DivGrid = styled.div`
text-align: ${props => props.alineado};
width: 100%;
padding-right: ${props => props.margR}px;
padding-top: ${props => props.margT}px;

`

export const DivThreeGrid = styled.div`
height: 40vh;
`

export const DivUsuariosRegistradosGrid = styled.div`
background-color: #202A5D;
text-align: ${props => props.alineado};
width: 100%;
height: 572px;
margin-right: ${props => props.margR}px;
display: grid;
grid-template-columns: repeat(3, 1fr);
text-align: center;
justify-content: center;
align-items: center;



/* responsive xs */
@media screen and (min-width: 1px) and (max-width: 767px) {
    /* grid-template-columns: repeat(1, 1fr);} */
    height: auto;
    padding: 10px;
}
/* responsive sm  */
@media screen and (min-width: 768px) and (max-width: 991px) {
    /* grid-template-columns: repeat(2, 1fr); */
    height: auto;
    padding: 1px;
}
`

export const MenuDesplegableGrid = styled.span`
   display: none;
   
/* responsive xs */
@media screen and (min-width: 1px) and (max-width: 767px) {
display: block;
}

:hover{
    cursor: pointer;
}
`

export const UlGrid = styled.ul`
/* responsive xs */
@media screen and (min-width: 1px) and (max-width: 767px) {
display: none;
}
/* responsive sm  */
@media screen and (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: repeat(1, 1fr);
}
/* responsive md  */
@media screen and (min-width: 992px) and (max-width: 1199px) {
    grid-template-columns: repeat(1, 1fr);
}
  /* responsive lg  */
@media screen and (min-width: 1200px) {
    grid-template-columns: repeat(1, 1fr);
} 
`

export const LiGrid = styled.li`
display: inline;
padding-left: 10px;
font-size: 18px;
font-family: 'Encode Sans Expanded', sans-serif;
font-family: 'Nunito Sans', sans-serif;

:hover{
    cursor: pointer;
    }



`

export const BtnIniciarSesionGrid = styled.button`
border: solid 1px white;
background-color: transparent;
color: white;
padding: 10px 30px 10px 30px;
font-size: 18px;
font-family: 'Encode Sans Expanded', sans-serif;
font-family: 'Nunito Sans', sans-serif;

:hover{
cursor: pointer;
}
`






// FOOTER
export const FooterGrid = styled.footer`
background-color: black;
color: white;
grid-column-start: 1;
grid-column-end: -1;
padding: 30px;

`