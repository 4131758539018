import styled from "styled-components";

// CONTAINER DE LA IMAGEN
export const IconsStyle = styled.img`
width: 121.32px;
/* height: 99.26px; */
text-align: right;
justify-content: right;
align-items: center;

:hover {
    cursor: pointer;
}


/* responsive xs */
@media screen and (min-width: 1px) and (max-width: 767px) {
    width: 40.32px;

 }
 
 /* responsive sm  */
 @media screen and (min-width: 768px) and (max-width: 991px) {
    width: 60.32px;
 }

 /* responsive md  */
 @media screen and (min-width: 992px) and (max-width: 1199px) {
    width: 90.32px;
 }
`



// Texts

export const H1Icon = styled.h1`
color: white;
font-size: 65px;
font-weight: bold;
line-height: 1;


/* responsive xs */
@media screen and (min-width: 1px) and (max-width: 767px) {
    font-size: 18px;
 }
 /* responsive sm  */
 @media screen and (min-width: 768px) and (max-width: 991px) {
    font-size: 25px;
 }

 /* responsive md  */
 @media screen and (min-width: 992px) and (max-width: 1199px) {
    font-size: 45px;
 }
`



export const PsmallIcon = styled.p`
color: white;
font-size: 26px;
font-family: 'Encode Sans Expanded', sans-serif;
font-family: 'Nunito Sans', sans-serif;
font-family: 'Source Sans Pro', sans-serif;

/* responsive xs */
@media screen and (min-width: 1px) and (max-width: 767px) {
    font-size: 16px;
 }
 /* responsive sm  */
 @media screen and (min-width: 768px) and (max-width: 991px) {
    font-size: 18px;
 }

 /* responsive md  */
 @media screen and (min-width: 992px) and (max-width: 1199px) {
    font-size: 22px;
 }
`



