import styled from "styled-components";





// BODY
export const BodyGrid = styled.body`
background-color: #506D84;
`

// HEADER
export const HeaderGrid = styled.header`
background-color: #202a5d;
color: white;
grid-column-start: 1;
grid-column-end: -1;
`


// CONTAINER
export const ContainerGrid = styled.div`
/* background-color: #6B4F4F; */
width: 100%;
max-width: 1000px;
border: 5px solid #000;
text-align: center;
margin: auto;
line-height: 50px;
gap: 5px;
display: grid;
grid-template-columns: 1fr 4fr 1fr;
grid-template-rows:  repeat(1, 1fr);
`

// SIDEBAR
export const SidebarGrid = styled.div`
background-color: #22577A;
display: block;
color: white;
box-shadow: 2px 2px 10px rgba(0,0,0, .25);
grid-row-start: 2;
grid-row-end: -1;
`


export const DivCentralGrid = styled.div`
background-color: #2A0944;
box-shadow: 2px 2px 10px rgba(0,0,0, .25);
display: block;
color: white;

display: grid;
gap: 10px;
grid-template-columns: repeat(3, 1fr) ;
grid-template-rows: 1fr 1fr  1fr ;
grid-row-start: 2;
grid-row-end: -1;
`




export const DivArticleGrid = styled.div`
background-color: #C31F13;
display: block;
color: white;
box-shadow: 2px 2px 10px rgba(0,0,0, .25);
`

export const DivGrid = styled.div`
background-color: #FFBD35;
display: block;
color: white;
box-shadow: 2px 2px 10px rgba(0,0,0, .25);
`









// FOOTER
export const FooterGrid = styled.footer`
background-color: black;
color: white;
grid-column-start: 1;
grid-column-end: -1;
`